import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { getAllRoles } from "@shared/utils/getAllRoles";
import { Plus, TextAlignJustify, Wrench } from "phosphor-react";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";

import CreateWorkOrder from "./work-order/Create";
import { OpenTicketsAtom } from "../atoms/_openTicket";
import Machine3DTab from "../components/_machine3DTab";
import ComponentsTab from "../components/machine/ComponentsTab";
import {
  MACHINE_DETAIL_PARTS_TAB_INDEX,
  MAX_TEXT_DISPLAY_LENGTH,
} from "../constants/global";

import getEnums from "$/settings/enums";
import TEMPLATE_TYPES from "$/settings/enums/machineTemplate/index.json";
import ticketTypes from "$/settings/enums/ticket/_types.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { globalLoginPopupForGMU } from "~/atoms/_global";
import { Drawer } from "~/components/_drawer";
import EmptySpace from "~/components/_emptySpace";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import { Tab, Tabs } from "~/components/_tabs";
import { Accordion } from "~/components/accordion/_accordion";
import BoxUIElement from "~/components/boxUIElement";
import useAuth from "~/components/general/_use-auth";
import TextEditor from "~/components/general/editor";
import { serialize } from "~/components/general/editor/utils";
import AppHeader from "~/components/header/AppHeader";
import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import { MachineHistory } from "~/components/machine/MachineHistory";
import PreventiveMaintenance from "~/components/machine/preventiveMaintenance/PreventiveMaintenance";
import { PartRow } from "~/components/parts/_part-raw";
import PartsPreview from "~/components/parts/_partsPreview";
import { NoUserAssignedIcon, OpenFolderIcon } from "~/icons";
import {
  getComponentsByParams,
  getMachineByUuid,
  getMachinePartsByUuid,
  listAllPreventiveMaintenanceEvents,
} from "~/services";
import { getMachineThumbnail } from "~/utils";
import { trimText } from "~/utils/_initials";

const templateTypes = getEnums(TEMPLATE_TYPES, "reference");
const ROLES = getAllRoles();
const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const MachinesQrcSingle = ({ intl, machineUuid }) => {
  const { messages } = intl;
  const { user } = useAuth();
  const history = useHistory();
  let { machineUuid: uuid } = useParams();
  uuid = uuid ?? machineUuid;
  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.productionLine,
  );
  const isMachineDocumentationEnabled =
    user.facility.isMachineDocumentationEnabled;
  const isPreventiveMaintenanceEventsEnabled =
    user.facility.isPreventiveMaintenanceEventsEnabled;
  const isComponentsPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.components,
  );
  const is3DModelPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?._3DModels,
  );

  const [showPreview, setShowPreview] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState(null);
  const [partSearchTerm, setPartSearchTerm] = React.useState("");
  const [componentSearchTerm, setComponentSearchTerm] = React.useState("");
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);
  const [gmuLoginCoil, setGMULoginCoil] = useRecoilState(
    globalLoginPopupForGMU,
  );

  // Calling get machine queries
  const [machine] = getMachineByUuid(uuid);
  const [inventoryParts, inventoryPartsLoading] = getMachinePartsByUuid(uuid);

  const id = useMemo(() => machine?._id, [machine?._id]);
  const isGMU = useMemo(() => user?.role === ROLES.GMU.VALUE, [user?.role]);

  const machineDescription = useMemo(() => {
    let description = "";
    if (serialize(machine?.description)) {
      description = machine?.description;
    } else {
      if (machine?.template?.type === templateTypes?.machineTemplate) {
        description = machine?.template?.description;
      } else {
        description = "";
      }
    }
    return description;
  }, [machine]);

  // Preventive maintenance Events
  const { events, loading: fetchingEvents } =
    listAllPreventiveMaintenanceEvents({
      where: {
        oem_in: user?.oem?._id,
        machine_in: id,
      },
    });

  const {
    components = [],
    loading: componentsLoading,
    totalCount,
    handleFetchMore,
  } = getComponentsByParams({
    where: {
      machine: id,
      searchQuery: componentSearchTerm,
    },
  });

  const handleNameClick = (partId) => {
    const selectedPart = inventoryParts.find(
      (part) => part.part._id === partId,
    );
    setShowPreview(true);
    setSelectedPart(selectedPart.part);
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedPart(null);
  };

  const installationDate = useMemo(() => {
    return machine?.customFields?.find(
      (field) =>
        field?.fieldId?.slug === "installation-date" &&
        field?.fieldId?.isAdditionalField,
    );
  }, [machine]);

  // Filtered Parts or All Parts
  const filteredMchineParts = useMemo(() => {
    return partSearchTerm
      ? inventoryParts.filter((part) => {
          return (
            part?.part?.name
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase()) ||
            part?.part?.articleNumber
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase())
          );
        })
      : inventoryParts;
  }, [partSearchTerm, inventoryParts]);

  const renderMacineThumbnail = () => {
    let thumbnailToShow = getMachineThumbnail(machine, true);

    return (
      <>
        {thumbnailToShow && (
          <figure className="machine-thumbnail">
            <img
              src={thumbnailToShow}
              alt={machine?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
        )}
      </>
    );
  };

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: intl?.messages?.assets.breadCrumbs.machines,
        link: `/${intl?.locale}/app/assets/machines`,
      },
      { label: trimText(machine?.name) || "" },
    ],
    [machine?.name],
  );
  const handleRestrictedTabClick = () => {
    if (isGMU) {
      setGMULoginCoil({
        ...gmuLoginCoil,
        showPopup: true,
      });
    }
  };

  const createWorkOrder = (isPartsRequest = false, partLabel = "") =>
    setOpenTicketCoil({
      ...openTicketCoil,
      isDrawerOpen: true,
      defaultSelectedMachine: { ...machine, inventoryParts },
      isPartsRequest,
      description: partLabel,
    });

  return (
    <>
      <CreateWorkOrder buttonComponent={() => {}} />
      <div className="single-machine-container">
        {!isGMU && (
          <button
            className={`btn-v2 primary-btn-v2 single-machine-create-work-order-mobile-button ${
              history.location.state?.activeTab ===
              MACHINE_DETAIL_PARTS_TAB_INDEX
                ? "above-footer"
                : ""
            }`}
            onClick={() => createWorkOrder()}
          >
            <Plus size={20} />
          </button>
        )}
        <div className="fw-page-header u-flex u-items-center u-width-100">
          <AppHeader className="bordered u-width-100">
            {!isGMU && (
              <>
                <Breadcrumbs options={breadCrumbs} />

                <div className="u-flex u-items-center desktop-element">
                  <Button
                    text={messages?.header?.ticketListing?.action}
                    onClick={() => createWorkOrder()}
                    className="ml-lg"
                  />
                </div>
                {/* Hamburger component is only for mobile view */}
                <Hamburger />
              </>
            )}
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100 u-padding-r-0">
          <div className="fw-page-main-content u-width-100">
            <div className="fw-machine-title">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="max-md:line-clamp-2 max-md:text-ellipsis max-md:whitespace-normal"
              >
                {trimText(machine?.name)}
              </Headline>
            </div>
            <div className="page-details-tab">
              <Tabs>
                <Tab
                  title={
                    intl?.messages?.machines?.machineDetails.titleTabs.details
                  }
                >
                  <div className="machine-details-container u-width-100">
                    <div className="machine-details-content u-width-100">
                      <div className="machine-meta-container u-items-center">
                        {renderMacineThumbnail()}
                        <div className="machine-meta">
                          <Label size={LABEL_SIZES.SMALL}>
                            {messages?.machines?.details}
                          </Label>
                          <EmptySpace height="14px"></EmptySpace>
                          <div className="u-flex u-width-100">
                            <div className="machine-meta-info u-margin-r-5">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="max-md:mr-2xs no-styles"
                              >
                                {intl?.messages?.machines?.serialNum}
                              </BodyText>
                              <EmptySpace height="4px"></EmptySpace>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                className="no-styles"
                              >
                                {trimText(machine?.serialNumber)}
                              </BodyText>
                            </div>
                            {installationDate?.fieldId?.enabled && (
                              <div className="machine-meta-info">
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  color="text-secondary"
                                  className="max-md:mr-2xs no-styles"
                                >
                                  {messages?.machines?.installationDate}
                                </BodyText>
                                <EmptySpace height="4px"></EmptySpace>
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  className="no-styles"
                                >
                                  {installationDate?.value}
                                </BodyText>
                              </div>
                            )}
                            {isProductionLinePaid && (
                              <div className="machine-meta-info">
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  color="text-secondary"
                                  className="max-md:mr-2xs no-styles"
                                >
                                  {intl?.messages?.machines?.machineDetails
                                    ?.detailsTab?.productionLine + ":"}
                                </BodyText>
                                <EmptySpace height="4px"></EmptySpace>

                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  color="text-brand"
                                  className="no-styles"
                                >
                                  {trimText(
                                    machine?.productionLine?.name,
                                    MAX_TEXT_DISPLAY_LENGTH,
                                  )}
                                </BodyText>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <Accordion
                        icon={<TextAlignJustify size={12} weight="bold" />}
                        title={messages?.machines?.description}
                      >
                        <TextEditor
                          content={machineDescription}
                          placeholder={" "}
                          readOnly={true}
                        />
                      </Accordion>
                    </div>
                  </div>
                </Tab>

                {isMachineDocumentationEnabled && (
                  <Tab
                    onRestrictedTabClick={handleRestrictedTabClick}
                    restricted={isGMU}
                    title={
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .documentation
                    }
                  >
                    <>
                      {machine?.template &&
                        machine?.template?.documentFolders?.externalId && (
                          <BoxUIElement
                            key={machine?.template?.documentFolders?.externalId}
                            token={user?.foldersAccessToken}
                            entityId={
                              machine?.template?.documentFolders?.externalId
                            }
                            rootFolderId={
                              machine?.template?.documentFolders?.externalId
                            }
                            entityName={machine?.name}
                            canUpload={false}
                            canRename={false}
                            canDelete={false}
                            canCreateNewFolder={false}
                          />
                        )}
                      {!machine?.template &&
                        machine?.documentFolders?.externalId && (
                          <BoxUIElement
                            key={machine?.documentFolders?.externalId}
                            token={user?.foldersAccessToken}
                            entityId={machine?.documentFolders?.externalId}
                            rootFolderId={machine?.documentFolders?.externalId}
                            entityName={machine?.name}
                            canUpload={false}
                            canRename={false}
                            canDelete={false}
                            canCreateNewFolder={false}
                          />
                        )}
                    </>
                  </Tab>
                )}
                {isComponentsPaid && (
                  <Tab
                    title={`${
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .components
                    } (${totalCount ?? 0})`}
                    restricted={isGMU}
                    onRestrictedTabClick={handleRestrictedTabClick}
                  >
                    <ComponentsTab
                      components={components}
                      intl={intl}
                      machine={machine}
                      searchTerm={componentSearchTerm}
                      setSearchTerm={setComponentSearchTerm}
                      loading={componentsLoading}
                      totalCount={totalCount}
                      handleFetchMore={handleFetchMore}
                    />
                  </Tab>
                )}
                <Tab
                  onRestrictedTabClick={handleRestrictedTabClick}
                  restricted={isGMU}
                  title={
                    intl?.messages?.machines?.machineDetails.titleTabs.history
                  }
                >
                  <MachineHistory intl={intl} machine={machine} />
                </Tab>
                <Tab
                  onRestrictedTabClick={handleRestrictedTabClick}
                  restricted={isGMU}
                  title={
                    <span className="u-flex u-items-center u-width-100">
                      {intl?.messages?.machines?.machineDetails.titleTabs.parts}{" "}
                      &nbsp;
                      {inventoryPartsLoading ? (
                        <div className="loader" />
                      ) : (
                        `(${inventoryParts?.length || 0})`
                      )}
                    </span>
                  }
                >
                  <div className="spare-part-tab-content">
                    <div className="u-flex u-items-center u-justify-between parts-table-top">
                      <button
                        className="add-new-field-btn"
                        onClick={() => createWorkOrder(true)}
                      >
                        <Plus size={12} weight="bold" />
                        {messages?.parts?.partsRequest}
                      </button>
                      <div className="max-lg:hidden">
                        <SearchInput
                          onChange={setPartSearchTerm}
                          value={partSearchTerm}
                          placeholder={
                            intl?.messages?.parts
                              ?.machinePartTabSearchPlaceholder
                          }
                        />
                      </div>
                    </div>

                    {/* MobileSearch component is only for mobile view */}
                    <MobileSearch
                      onChange={setPartSearchTerm}
                      value={partSearchTerm}
                      className="spart-part-mobile-search"
                      placeholder={
                        intl?.messages?.parts?.machinePartTabSearchPlaceholder
                      }
                    />
                    {inventoryParts?.length > 0 ? (
                      <>
                        <div className="parts-table__head u-flex sm-hide">
                          <div className="parts-table__col u-width-4">
                            <BodyText
                              size={BODY_TEXT_SIZES.X_SMALL}
                              color="text-secondary"
                            >
                              {intl?.messages?.parts?.labels.name}
                            </BodyText>
                          </div>
                          <div className="parts-table__col u-width-4">
                            <BodyText
                              size={BODY_TEXT_SIZES.X_SMALL}
                              color="text-secondary"
                            >
                              {intl?.messages?.parts?.labels.description}
                            </BodyText>
                          </div>
                          <div className="parts-table__col u-width-2"></div>
                          <div className="parts-table__col u-width-2">
                            <BodyText
                              size={BODY_TEXT_SIZES.X_SMALL}
                              color="text-secondary"
                            >
                              {intl?.messages?.parts?.labels.articleNumber}
                            </BodyText>
                          </div>
                        </div>
                        {filteredMchineParts.map((part, index) => {
                          return (
                            <PartRow
                              key={part.part._id}
                              part={part.part}
                              machine={machine}
                              handlers={{ handleNameClick }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <NotFoundComponent
                        icon={<NoUserAssignedIcon />}
                        title={intl?.messages?.parts?.noPartsFound}
                      />
                    )}
                  </div>
                </Tab>
                {isPreventiveMaintenanceEventsEnabled &&
                  events?.length > 0 &&
                  !fetchingEvents && (
                    <Tab
                      onRestrictedTabClick={handleRestrictedTabClick}
                      restricted={isGMU}
                      title={`${intl?.messages?.machines?.machineDetails.titleTabs.preventiveMaintenance} (${events?.length})`}
                    >
                      <PreventiveMaintenance events={events} machineId={id} />
                    </Tab>
                  )}
                {is3DModelPaid && (
                  <Tab
                    onRestrictedTabClick={handleRestrictedTabClick}
                    restricted={isGMU}
                    title={
                      intl?.messages?.machines?.machineDetails.titleTabs._3D
                    }
                  >
                    {machine?._3dModelUrl ? (
                      <Machine3DTab
                        machine={machine}
                        url={machine?._3dModelUrl}
                        onRequestPart={(label) => createWorkOrder(true, label)}
                      />
                    ) : (
                      <BodyText size={BODY_TEXT_SIZES.SMALL}>
                        {
                          intl?.messages?.machines?.machineDetails._3DTab
                            .no3DModelMessage
                        }
                      </BodyText>
                    )}
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        hideFooter
        isOpen={showPreview}
        onClose={handlePreviewClose}
        title={selectedPart?.name}
      >
        <PartsPreview data={selectedPart} machine={machine} />
      </Drawer>
    </>
  );
};

export const renderMachineHistory = ({ intl, machineHistory }) => {
  return machineHistory?.map((item, index) => {
    return (
      <Link
        to={`/${intl?.locale}/app/${item.status}-tickets/${item._id}`}
        key={index}
        className={`ticket-item-wrapper ticket-item-wrapper--smaller ticket-item-wrapper--timeline ${
          item?.status === "closed" && "closed-ticket-wrapper"
        }`}
      >
        <div className="left-side-content">
          <span
            className={`ticket-icon btn-v2 secondary-icon-btn-v2 ${
              ticketTypes[item.ticketType] === ticketTypes?.SparePartOrder
                ? "sparePart-order-icon"
                : ""
            }`}
          >
            {ticketTypes[item.ticketType] === ticketTypes?.SparePartOrder ? (
              <Wrench size={20} />
            ) : (
              <OpenFolderIcon />
            )}
          </span>
          <div className="title-wrapper">
            <h2>{item?.title}</h2>
            <p>
              {new Date(item?.createdAt).toLocaleDateString("en-US")} &nbsp;
              &#8226; &nbsp; {item?.ticketId}
            </p>
          </div>
        </div>
      </Link>
    );
  });
};

export default MachinesQrcSingle;
