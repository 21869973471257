import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { Plus, TextAlignJustify } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";

import CreateWorkOrder from "./work-order/Create";
import { OpenTicketsAtom } from "../atoms/_openTicket";
import Machine3DTab from "../components/_machine3DTab";
import ContentLoading from "../components/ContentLoading";
import ComponentsTab from "../components/machine/ComponentsTab";
import PartsTableWithPreview from "../components/parts/_parts-table-with-preview";
import {
  MACHINE_DETAIL_PARTS_TAB_INDEX,
  MAX_TEXT_DISPLAY_LENGTH,
} from "../constants/global";

import getEnums from "$/settings/enums";
import TEMPLATE_TYPES from "$/settings/enums/machineTemplate/index.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { Drawer } from "~/components/_drawer";
import EmptySpace from "~/components/_emptySpace";
import { Tab, Tabs } from "~/components/_tabs";
import { Accordion } from "~/components/accordion/_accordion";
import BoxUIElement from "~/components/boxUIElement";
import useAuth from "~/components/general/_use-auth";
import TextEditor from "~/components/general/editor";
import { serialize } from "~/components/general/editor/utils";
import AppHeader from "~/components/header/AppHeader";
import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import { MachineHistory } from "~/components/machine/MachineHistory";
import PreventiveMaintenance from "~/components/machine/preventiveMaintenance/PreventiveMaintenance";
import PartsPreview from "~/components/parts/_partsPreview";
import {
  getComponentsByParams,
  getMachineById,
  getMachinePartsById,
  listAllPreventiveMaintenanceEvents,
} from "~/services";
import { getMachineThumbnail } from "~/utils";
import { trimText } from "~/utils/_initials";

const templateTypes = getEnums(TEMPLATE_TYPES, "reference");
const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const SingleMachine = ({ intl }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const { messages } = useIntl();
  const history = useHistory();
  const [showPreview, setShowPreview] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState(null);
  const [partSearchTerm, setPartSearchTerm] = React.useState("");
  const [componentSearchTerm, setComponentSearchTerm] = React.useState("");
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);
  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.productionLines,
  );
  const isMachineDocumentationEnabled =
    user.facility.isMachineDocumentationEnabled;
  const isPreventiveMaintenanceEventsEnabled =
    user.facility.isPreventiveMaintenanceEventsEnabled;
  const isComponentsPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.components,
  );
  const is3DModelPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?._3DModels,
  );

  // Calling get queries
  const [machine] = getMachineById(id);
  const [inventoryParts, inventoryPartsLoading] = getMachinePartsById(id);

  const {
    components = [],
    loading: componentsLoading,
    totalCount,
    handleFetchMore,
  } = getComponentsByParams({
    where: {
      machine: id,
      searchQuery: componentSearchTerm,
    },
  });

  const machineDescription = useMemo(() => {
    let description = "";
    if (serialize(machine?.description)) {
      description = machine?.description;
    } else {
      if (machine?.template?.type === templateTypes?.machineTemplate) {
        description = machine?.template?.description;
      } else {
        description = "";
      }
    }
    return description;
  }, [machine]);

  // Get Preventive Maintanance Events
  const { events, loading: fetchingEvents } =
    listAllPreventiveMaintenanceEvents({
      where: {
        oem_in: user?.oem?._id,
        machine_in: id,
      },
    });

  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedPart(null);
  };

  const installationDate = useMemo(() => {
    return machine?.customFields?.find(
      (field) =>
        field?.fieldId?.slug === "installation-date" &&
        field?.fieldId?.isAdditionalField,
    );
  }, [machine]);

  // Filtered Parts or All Parts
  const filteredMchineParts = useMemo(() => {
    return partSearchTerm
      ? inventoryParts?.filter((part) => {
          return (
            part?.part?.name
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase()) ||
            part?.part?.articleNumber
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase())
          );
        })
      : inventoryParts;
  }, [partSearchTerm, inventoryParts]);

  const handleSearch = (value) => {
    setPartSearchTerm(value);
  };

  const renderMacineThumbnail = () => {
    let thumbnailToShow = getMachineThumbnail(machine, true);

    return (
      <>
        {thumbnailToShow && (
          <figure className="machine-thumbnail">
            <img
              src={thumbnailToShow}
              alt={machine?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
        )}
      </>
    );
  };

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: intl?.messages?.assets.breadCrumbs.assets,
        link: `/${intl?.locale}/app/assets`,
      },
      {
        label: intl?.messages?.assets.breadCrumbs.machines,
        link: `/${intl?.locale}/app/assets/machines`,
      },
      { label: trimText(machine?.name, MAX_TEXT_DISPLAY_LENGTH) || "" },
    ],
    [machine?.name],
  );

  const createWorkOrder = (isPartsRequest = false, partLabel = "") =>
    setOpenTicketCoil({
      ...openTicketCoil,
      isDrawerOpen: true,
      defaultSelectedMachine: { ...machine, inventoryParts },
      isPartsRequest,
      description: partLabel,
    });

  return (
    <>
      <CreateWorkOrder buttonComponent={() => {}} />
      <div className="single-machine-container">
        <button
          className={`btn-v2 primary-btn-v2 single-machine-create-work-order-mobile-button ${
            history.location.state?.activeTab === MACHINE_DETAIL_PARTS_TAB_INDEX
              ? "above-footer"
              : ""
          }`}
          onClick={() => createWorkOrder()}
        >
          <Plus size={20} />
        </button>
        <div className="fw-page-header u-flex u-items-center u-width-100">
          <AppHeader className="bordered u-width-100">
            <Breadcrumbs options={breadCrumbs} />
            <div className="u-flex u-items-center desktop-element">
              <Button
                text={messages?.header?.ticketListing?.action}
                className="ml-lg"
                onClick={() => createWorkOrder()}
              />
            </div>
            {/* Hamburger component is only for mobile view */}
            <Hamburger />
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100 u-padding-r-0">
          <div className="fw-page-main-content u-width-100">
            <div className="fw-machine-title">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="max-md:line-clamp-2 max-md:text-ellipsis max-md:whitespace-normal"
              >
                {trimText(machine?.name, 75)}
              </Headline>
            </div>
            <div className="page-details-tab">
              <Tabs>
                <Tab
                  title={
                    intl?.messages?.machines?.machineDetails.titleTabs.details
                  }
                >
                  <div className="machine-details-container u-width-100">
                    <div className="machine-details-content u-width-100">
                      <div className="machine-meta-container u-items-center">
                        {renderMacineThumbnail()}
                        <div className="machine-meta">
                          <Label size={LABEL_SIZES.SMALL}>
                            {messages?.machines?.details}
                          </Label>
                          <EmptySpace height="14px"></EmptySpace>
                          <div className="u-flex u-width-100">
                            <div className="machine-meta-info u-margin-r-5">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="max-md:mr-2xs no-styles"
                              >
                                {intl?.messages?.machines?.serialNum + ":"}
                              </BodyText>
                              <EmptySpace height="4px"></EmptySpace>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                className="no-styles"
                              >
                                {trimText(machine?.serialNumber, 75)}
                              </BodyText>
                            </div>
                            {installationDate?.fieldId?.enabled && (
                              <div className="machine-meta-info u-margin-r-5">
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  color="text-secondary"
                                  className="max-md:mr-2xs no-styles"
                                >
                                  {messages?.machines?.installationDate + ":"}
                                </BodyText>
                                <EmptySpace height="4px"></EmptySpace>
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  className="no-styles"
                                >
                                  {installationDate?.value}
                                </BodyText>
                              </div>
                            )}
                            {isProductionLinePaid &&
                              machine?.productionLine && (
                                <div className="machine-meta-info">
                                  <BodyText
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                    color="text-secondary"
                                    className="max-md:mr-2xs no-styles"
                                  >
                                    {intl?.messages?.machines?.machineDetails
                                      ?.detailsTab?.productionLine + ":"}
                                  </BodyText>
                                  <EmptySpace height="4px"></EmptySpace>
                                  <Link
                                    to={`/${intl.locale}/app/assets/production-lines/${machine?.productionLine?._id}`}
                                  >
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      color="text-brand"
                                      className="cursor-pointer"
                                    >
                                      {trimText(
                                        machine?.productionLine?.name,
                                        MAX_TEXT_DISPLAY_LENGTH,
                                      )}
                                    </BodyText>
                                  </Link>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <Accordion
                        icon={<TextAlignJustify size={12} weight="bold" />}
                        title={messages?.machines?.description}
                      >
                        <TextEditor
                          content={machineDescription}
                          placeholder={" "}
                          readOnly={true}
                        />
                      </Accordion>
                    </div>
                  </div>
                </Tab>

                {isMachineDocumentationEnabled && (
                  <Tab
                    title={
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .documentation
                    }
                  >
                    <>
                      {machine?.template &&
                        (!machine.template?.documentFolders?.externalId ? (
                          <ContentLoading />
                        ) : (
                          <BoxUIElement
                            key={machine.template?.documentFolders?.externalId}
                            token={user?.foldersAccessToken}
                            entityId={
                              machine.template?.documentFolders?.externalId
                            }
                            rootFolderId={
                              machine.template?.documentFolders?.externalId
                            }
                            entityName={machine?.name}
                            canUpload={false}
                            canRename={false}
                            canDelete={false}
                            canCreateNewFolder={false}
                          />
                        ))}
                      {!machine?.template &&
                        (!machine?.documentFolders?.externalId ? (
                          <ContentLoading />
                        ) : (
                          <BoxUIElement
                            key={machine.documentFolders?.externalId}
                            token={user?.foldersAccessToken}
                            entityId={machine.documentFolders?.externalId}
                            rootFolderId={machine.documentFolders?.externalId}
                            entityName={machine?.name}
                            canUpload={false}
                            canRename={false}
                            canDelete={false}
                            canCreateNewFolder={false}
                          />
                        ))}
                    </>
                  </Tab>
                )}
                {isComponentsPaid && (
                  <Tab
                    title={`${
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .components
                    } (${totalCount ?? 0})`}
                  >
                    <ComponentsTab
                      components={components}
                      intl={intl}
                      machine={machine}
                      searchTerm={componentSearchTerm}
                      setSearchTerm={setComponentSearchTerm}
                      loading={componentsLoading}
                      totalCount={totalCount}
                      handleFetchMore={handleFetchMore}
                    />
                  </Tab>
                )}
                {user?.oem?.paidFeatures.includes(
                  paidFeaturesRef?.workManagement,
                ) && (
                  <Tab
                    title={
                      intl?.messages?.machines?.machineDetails.titleTabs.history
                    }
                  >
                    <MachineHistory intl={intl} machine={machine} />
                  </Tab>
                )}
                <Tab
                  title={
                    <span className="u-flex u-items-center u-width-100">
                      {intl?.messages?.machines?.machineDetails.titleTabs.parts}{" "}
                      &nbsp;
                      {inventoryPartsLoading ? (
                        <div className="loader" />
                      ) : (
                        `(${inventoryParts?.length || 0})`
                      )}
                    </span>
                  }
                >
                  <div className="spare-part-tab-content">
                    <div className="u-flex u-items-center u-justify-between parts-table-top">
                      <Button
                        variant={BUTTON_VARIANTS.LINK}
                        text={messages?.parts?.partsRequest}
                        leadingIcon={<Plus size={12} weight="bold" />}
                        onClick={() => createWorkOrder(true)}
                      />
                      <div className="max-lg:hidden">
                        <SearchInput
                          onChange={handleSearch}
                          value={partSearchTerm}
                          placeholder={
                            intl?.messages?.parts
                              ?.machinePartTabSearchPlaceholder
                          }
                        />
                      </div>
                    </div>
                    {/* MobileSearch component is only for mobile view */}
                    <MobileSearch
                      onChange={handleSearch}
                      value={partSearchTerm}
                      className="spart-part-mobile-search"
                      placeholder={
                        intl?.messages?.parts?.machinePartTabSearchPlaceholder
                      }
                    />
                    <PartsTableWithPreview
                      loading={inventoryPartsLoading}
                      hasInventoryParts={inventoryParts?.length > 0}
                      notFoundMessage={intl?.messages?.parts?.noPartsFound}
                      intl={intl}
                      filteredParts={filteredMchineParts}
                    />
                  </div>
                </Tab>
                {isPreventiveMaintenanceEventsEnabled &&
                  events?.length > 0 &&
                  !fetchingEvents && (
                    <Tab
                      title={`${intl?.messages?.machines?.machineDetails.titleTabs.preventiveMaintenance} (${events?.length})`}
                    >
                      <PreventiveMaintenance events={events} machineId={id} />
                    </Tab>
                  )}
                {is3DModelPaid && (
                  <Tab
                    title={
                      intl?.messages?.machines?.machineDetails.titleTabs._3D
                    }
                  >
                    {machine?._3dModelUrl ? (
                      <Machine3DTab
                        machine={machine}
                        url={machine?._3dModelUrl}
                        onRequestPart={(label) => createWorkOrder(true, label)}
                      />
                    ) : (
                      <BodyText size={BODY_TEXT_SIZES.SMALL}>
                        {
                          intl?.messages?.machines?.machineDetails._3DTab
                            .no3DModelMessage
                        }
                      </BodyText>
                    )}
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        hideFooter
        isOpen={showPreview}
        onClose={handlePreviewClose}
        title={selectedPart?.name}
      >
        <PartsPreview data={selectedPart} machine={machine} />
      </Drawer>
    </>
  );
};

export default SingleMachine;
